<template>
  <div class="up-box">
    <el-upload
      v-if="showTextButton"
      :action="qiniuupload"
      :multiple="true"
      :file-list="filelist"
      list-type="text"
      :on-success="handleAvatarSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :data="qiniuData"
      :show-file-list="false"
    >
      <slot>
        <el-button
          v-bind="$attrs"
          size="small"
          :style="{width:'120px'}"
          type="primary"
        >{{ label }}</el-button>
      </slot>
    </el-upload>

    <el-upload
      v-else
      :action="qiniuupload"
      list-type="picture-card"
      :multiple="true"
      :file-list="filelist"
      :on-success="handleAvatarSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :data="qiniuData"
    >
      <i slot="default" class="el-icon-plus" />
      <div slot="file" slot-scope="{ file }" class="imgbox">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">

        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete" />
          </span>
        </span>

        <el-input
          :id="file.file_id"
          size="mini"
          class="updatefrom"
          :value="file.source"
          placeholder="图片来源"
          name="updatefrom"
          type="text"
          @blur="updateSource"
        />
        <el-input
          :id="file.file_id"
          size="mini"
          :value="file.sort"
          placeholder="排序"
          name="updatesort"
          class="updatesort"
          type="text"
          @blur="updateSort"
        />
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { qnToken, editSource } from '@/api/website/qiniu'
Array.prototype.contains = function(obj) {
  var index = this.length
  while (index--) {
    if (this[index] === obj) {
      return true
    }
  }
  return false
}


Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i
  }
  return -1
}

Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}

export default {

  props: {
    btntype: {
      type: String,
      default: 'text' // text/picture-card
    },
    pics: {
      type: Array,
      default: function() {
        return []
      }
    },
    label: {
      type: String,
      default: '上传'
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    limittype: {
      type: String,
      default: 'file' // file  || pic  上传类型默认为图片  可以使用file 则上传文件类型
    }

  },
  data() {
    return {
      // qiniuupload: 'http://upload.qiniu.com', // 七牛云上传地址
      qiniuupload: 'https://up.qiniup.com', // 七牛云上传地址
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      listtype: 'text',
      showTextButton: false, // true ：false  显示：隐藏 text 模式下的button
      filelist: [],
      qiniuData: {
        // key: '',
        token: ''
      },
      // 七牛云返回储存图片的子域名
      // upload_qiniu_addr: "http://abc.clouddn.com/",
      imageUrl: ''
    }
  },

  watch: {
    pics(arr) {
      arr.map((res) => {
        const newobj = {};
        (newobj.url = res.url), (newobj.file_id = res.id)
        newobj.source = res.source
        this.filelist.push(newobj)
      })
    },
    params(obj) {
      this.params = obj
    }
  },

  mounted() {
    if (this.btntype === 'text') {
      this.showTextButton = true
    }
  },
  methods: {

    // 更新资源
    updateSource(e) {
      console.log('eeeee', e)
      editSource({ file_id: e.target.id, source: e.target.value }).then(
        (res) => {
          if (res.code) {
            // 更新filelist
            this.updateFilelist({ file_id: e.target.id, source: e.target.value })

            this.$message('编辑成功...')
          }
        }
      )
    },

    // 更新列表
    updateFilelist({ file_id, source }) {
      this.filelist.map(file => {
        if (file_id === file.file_id) {
          file.source = source
        }
        return file
      })
      // console.log(this.filelist)
    },

    getQiniuToken: async function(obj) {
      const _this = this
      // console.log('getQiniuToken',this.params)
      // console.log("=======")
      const tokenParams = { ..._this.params, ...obj }

      // console.log(tokenParams)
      const res = await qnToken(tokenParams)
      //  .then(function (res) {
      if (res.code) {
        _this.qiniuData.token = res.data.token
        // _this.qiniuData.key = res.data.key
      } else {
        _this.$message({
          message: res.data.info,
          duration: 2000,
          type: 'warning'
        })
      }
      // });
    },

    beforeAvatarUpload: async function(file) {
      await this.getQiniuToken({ 'name': file.name })
      const a = file.type.split('/')
      const len = a.length
      const prefix = a[len - 1]
      this.qiniuData.prefix = prefix

      // console.log('111')
      // return false
      if (this.limittype === 'file') {
        const isLt2M = file.size / 1024 / 1024 < 100 // 100M
        if (!isLt2M) {
          this.$message.error('大小不能超过 100MB!')
          return false
        }
      } else {
        const isJPG = file.type === 'image/jpeg'
        const isPNG = file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 100 // 100M
        if (!isJPG && !isPNG) {
          this.$message.error('图片只能是 JPG/PNG 格式!')
          return false
        }
        if (!isLt2M) {
          this.$message.error('图片大小不能超过 100MB!')
          return false
        }
      }
    },

    handleAvatarSuccess: function(res, file) {
      console.log('res', res, 'file', file)
      this.filelist.push({
        url: res.data.url,
        file_id: res.data.id,
        name: res.data.name,
        source: res.data.source
      })

      if (this.limittype === 'file') {
        this.$emit('fileinfo', file)
      } else {
        this.$emit('getid', this.filelist)
      }
    },

    handleError: function(res) {
      this.$message({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    },

    handleRemove(file) {
      this.filelist.remove(file)
      this.$emit('getid', this.filelist)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },
    updateSort() {

    }
  }
}
</script>

<style lang="scss">

.updatefrom {
  position: absolute;
  left:0;
  bottom: 40px;
  width:100%;
  height:30px;
}
.updatesort {
  position: absolute;
  left:0;
  bottom: 0%;
  width:100%;
  height:30px;
}
.el-upload-list--picture-card .el-upload-list__item{
  height:228px;
  border:none;
  border-radius: 0;
}
.el-upload-list--picture-card .el-upload-list__item-actions{
  height:148px;
  border:solid 1px #aaa;
   border-radius: 5px;
}
.el-upload-list{
  display: inline-block;
}
.imgbox{
  border:solid 1px #aaa;
  height:148px;
  border-radius: 5px;
  img{
    border-radius: 5px;
    width:100%;
  }
}
</style>
